import { Chapter } from '@customtypes/chapter';
import { getTutorialsInSection } from './content-utils';
import {
  getClientSideCookieForActiveSectionForTrack,
  getClientSideCookieForActiveTrack,
  getServerSideCookieForActiveSectionForTrack,
  getServerSideCookieForActiveTrack,
} from './cookie-utils';
import { Track } from '@customtypes/track';
import { SSRPropsContext } from 'next-firebase-auth';
import { ParsedUrlQuery } from 'querystring';
import { SubscribePageTrackingReferer } from '@customtypes/events';

export function getLearnLessonLink(
  {
    trackId,
    tutorialId,
    chapterId,
    lessonIndex,
  }: {
    trackId: number;
    tutorialId: number;
    chapterId: number;
    lessonIndex: number;
  },
  options: { isOnDemand: boolean } = {
    isOnDemand: process.env.NEXT_PUBLIC_DEPLOYMENT_TYPE === 'preview',
  },
) {
  if (options.isOnDemand) {
    return `/ssr/track/${trackId}/tutorial/${tutorialId}/chapter/${chapterId}/learn?lesson=${lessonIndex}`;
  } else {
    return `/web/track/${trackId}/tutorial/${tutorialId}/chapter/${chapterId}/learn?lesson=${lessonIndex}`;
  }
}

export function getChallengeLessonLink(
  {
    trackId,
    tutorialId,
    chapterId,
    lessonIndex,
  }: {
    trackId: number;
    tutorialId: number;
    chapterId: number;
    lessonIndex: number;
  },
  options: { isOnDemand: boolean } = {
    isOnDemand: process.env.NEXT_PUBLIC_DEPLOYMENT_TYPE === 'preview',
  },
) {
  if (options.isOnDemand) {
    return `/ssr/track/${trackId}/tutorial/${tutorialId}/chapter/${chapterId}/challenge?lesson=${lessonIndex}`;
  } else {
    return `/web/track/${trackId}/tutorial/${tutorialId}/chapter/${chapterId}/challenge?lesson=${lessonIndex}`;
  }
}

export function getRandomChallengeLessonLink({
  trackId,
  tutorialId,
  chapters,
}: {
  trackId: number;
  tutorialId: number;
  chapters: Chapter[];
}) {
  return getChallengeLessonLink({
    trackId,
    tutorialId,
    chapterId: chapters[Math.floor(Math.random() * chapters.length)].id,
    lessonIndex: 1,
  });
}

export function getTutorProjectLessonLink(
  {
    trackId,
    tutorialId,
    chapterId,
    lessonIndex,
    clearIDBProgressOnNavigation,
  }: {
    trackId: number;
    tutorialId: number;
    chapterId: number;
    lessonIndex: number;
    clearIDBProgressOnNavigation?: boolean;
  },
  options: { isOnDemand: boolean } = {
    isOnDemand: process.env.NEXT_PUBLIC_DEPLOYMENT_TYPE === 'preview',
  },
) {
  const clearProgessQueryParam = clearIDBProgressOnNavigation
    ? '&clearProgress=true'
    : '';

  if (options.isOnDemand) {
    return `/ssr/track/${trackId}/tutorial/${tutorialId}/chapter/${chapterId}/tutor?lesson=${lessonIndex}`;
  } else {
    return `/web/track/${trackId}/tutorial/${tutorialId}/chapter/${chapterId}/tutor?lesson=${lessonIndex}${clearProgessQueryParam}`;
  }
}

/**
 * Get the URL to the streak chapter end page
 *
 * @returns the url to the streak chapter end page
 */
export function getStreakUrl({
  trackId,
  tutorialId,
  chapterId,
  sectionIndex,
  prevXPCount,
}: {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  sectionIndex: number;
  prevXPCount: number;
}) {
  return `/web/streak?trackId=${trackId}&tutorialId=${tutorialId}&chapterId=${chapterId}&sectionIndex=${sectionIndex}&prevXPCount=${prevXPCount}`;
}

/**
 * Get URL to the daily goal page
 */
export function getDailyGoalUrl({
  trackId,
  tutorialId,
  chapterId,
  sectionIndex,
  prevXPCount,
}: {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  sectionIndex: number;
  prevXPCount: number;
}) {
  return `/web/daily-goal?trackId=${trackId}&tutorialId=${tutorialId}&chapterId=${chapterId}&sectionIndex=${sectionIndex}&prevXPCount=${prevXPCount}`;
}

export function getChapterEndProgressSyncUrl({
  trackId,
  tutorialId,
  chapterId,
  sectionIndex,
}: {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  sectionIndex: number;
}) {
  return `/web/syncProgress?trackId=${trackId}&tutorialId=${tutorialId}&chapterId=${chapterId}&sectionIndex=${sectionIndex}`;
}

/**
 * Constructor function to get the url for a specific track section
 *
 * @param trackId
 * @param sectionIndex
 * @returns
 */
export function getLinkToSection(trackId: number, sectionIndex: number) {
  return `/web/${trackId}/section/${sectionIndex}`;
}

/**
 * Function that returns the url to the redirect page that redirects to the
 * active section in case the cache cookies are not populated yet.
 *
 * This page is the default link in case the cache is empty.
 *
 * @returns the url to the redirect-to-active-section page
 */
export function getRedirectFallbackToActiveSectionUrl() {
  return '/web/redirect-to-active-section';
}

/**
 * Function that returns the url to the redirect page that redirects to the
 * active section in a specific track.
 *
 * @returns the url to the /active page for a given trackId
 */
export function getRedirectToActiveSectionForTrackUrl(trackId: number) {
  return `/web/${trackId}/section/active`;
}

/**
 * Gets the url to the active section details page based on the cache cookies.
 *
 * If either the active track cookie or the active section cookie is not set,
 * we return undefined early so that the implementer can decide what to do.
 *
 * @returns the url to the active section details page or undefined
 */
export function getActiveSectionDetailsUrl() {
  if (typeof window === 'undefined') {
    return;
  }

  const activeTrackId = getClientSideCookieForActiveTrack();

  if (!activeTrackId) {
    return;
  }

  const activeSectionId = getClientSideCookieForActiveSectionForTrack(
    Number(activeTrackId),
  );

  if (!activeSectionId) {
    return;
  }

  return `/web/${activeTrackId}/section/${activeSectionId}`;
}

/**
 * Gets the url to the active section details page based on the request context.
 *
 * This function is used on the `server side`.
 *
 * If either the active track cookie or the active section cookie is not set,
 * we return undefined early so that the implementer can decide what to do.
 *
 * @returns the url to the active section details page or undefined
 */
export function getActiveSectionDetailsUrlServerSide(
  ctx: SSRPropsContext<ParsedUrlQuery>,
) {
  const activeTrackId = getServerSideCookieForActiveTrack(ctx);

  if (!activeTrackId) {
    return;
  }

  const activeSectionId = getServerSideCookieForActiveSectionForTrack(
    ctx,
    Number(activeTrackId),
  );

  if (!activeSectionId) {
    return;
  }

  return `/web/${activeTrackId}/section/${activeSectionId}`;
}

/**
 * Gets the url to the active section details page based on a given track id.
 *
 * If we don't find an active section for the given track we
 * we return undefined early so that the implementer can decide what to do.
 *
 * @param trackId
 * @returns the url to the active section based on a track or undefined
 */
export function getActiveSectionDetailsUrlForGivenTrack(trackId: number) {
  if (typeof window === 'undefined') {
    return;
  }

  const activeSectionId = getClientSideCookieForActiveSectionForTrack(
    Number(trackId),
  );

  if (!activeSectionId) {
    return;
  }

  return `/web/${trackId}/section/${activeSectionId}`;
}

/**
 * Gets the url to the first lesson within a given track
 *
 * @param track
 * @returns the url to the first lesson within a track
 */
export function getFirstLessonLink(track: Track) {
  const firstSection = track.sections[0];
  const firstTutorial = getTutorialsInSection(firstSection, track)[0];

  return getLearnLessonLink({
    trackId: track.id,
    tutorialId: firstTutorial.id,
    chapterId: firstTutorial.chapters[0].id,
    lessonIndex: 1,
  });
}

export function getWeb2AppSubscribePageLink(options?: {
  redirectUrl?: string;
  trackingReferer?: SubscribePageTrackingReferer;
  selectedTrackId?: string;
}) {
  if (!options?.redirectUrl && !options?.trackingReferer) {
    return '/web2app/subscribe';
  }

  const queryParams = Object.entries(options)
    .map(([key, value]) => {
      if (key === 'redirectUrl') {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return `/web2app/subscribe?${queryParams}`;
}

export function getSubscribePageLink(options?: {
  redirectUrl?: string;
  trackingReferer?: SubscribePageTrackingReferer;
}) {
  if (!options?.redirectUrl && !options?.trackingReferer) {
    return '/web/subscribe';
  }

  const queryParams = Object.entries(options)
    .map(([key, value]) => {
      if (key === 'redirectUrl') {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return `/web/subscribe?${queryParams}`;
}

export function getProjectsPageLink() {
  return '/web/build';
}

type ProjectPageBacklinkOptions =
  | {
      type: 'build';
    }
  | { type: 'section details'; trackId: number; sectionIndex: number };

export function getProjectPageLink(
  projectId: number,
  backlinkOptions: ProjectPageBacklinkOptions,
) {
  let backlink = getProjectsPageLink();

  switch (backlinkOptions.type) {
    case 'build':
      backlink = getProjectsPageLink();
      break;
    case 'section details':
      backlink = getLinkToSection(
        backlinkOptions.trackId,
        backlinkOptions.sectionIndex,
      );
      break;
    default:
      throw new Error('Invalid backlink type');
  }

  return `/web/build/${projectId}?backlink=${backlink}`;
}

export function getProjectDonePageLink({
  trackId,
  tutorialId,
  chapterId,
  sectionIndex,
}: {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  sectionIndex: number;
}) {
  return `/web/project-done?trackId=${trackId}&tutorialId=${tutorialId}&chapterId=${chapterId}&sectionIndex=${sectionIndex}`;
}

export function getOnboardingCheckPageLink() {
  return '/onboarding-check';
}

export function getOnboardingPageLink() {
  return '/web/onboarding/chat';
}

export function getEventsPageLink() {
  return '/web/events';
}

export function getMachinePageLink(machineId: string, backlink?: string) {
  return `/web/build/machines/${machineId}${backlink ? `?backlink=${backlink}` : ''}`;
}

export function getMachineForProjectCustomizationPageLink({
  machineId,
  trackId,
  tutorialId,
  chapterId,
  lastLessonId,
  backlink,
}: {
  machineId: string;
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lastLessonId: number;
  backlink?: string;
}) {
  return `/web/build/machines/${machineId}?trackId=${trackId}&tutorialId=${tutorialId}&chapterId=${chapterId}&lastLessonId=${lastLessonId}${backlink ? `&backlink=${backlink}` : ''}`;
}

export function getMachinePageLinkForCreationFromBuildTab(
  machineId: string,
  backlink?: string,
) {
  return `/web/build/machines/${machineId}?setupSource=build-tab${backlink ? `&backlink=${backlink}` : ''}`;
}

export function getLibraryPageLink() {
  return '/web/library';
}

export function getWeb2AppSignupPageLink(redirectUrl?: string) {
  if (!redirectUrl) {
    return '/web2app/signup';
  }

  return `/web2app/signup?redirectUrl=${redirectUrl}`;
}

export function getWeb2AppSuccessPageLink(redirectUrl?: string) {
  if (!redirectUrl) {
    return '/web2app/success';
  }

  return `/web2app/success?redirectUrl=${redirectUrl}`;
}

export function getRegisterPageLink(redirectUrl?: string) {
  if (!redirectUrl) {
    return '/web/register';
  }

  return `/web/register?redirectUrl=${redirectUrl}`;
}

export function getGlossaryPageLink() {
  return '/glossary';
}
