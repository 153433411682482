import { ABTestsMap } from '@customtypes/ab-tests';

// add additional ab test names here using |
export type ABTestNames =
  | 'demo-ab-test'
  | 'experiment-first-time-web-user-discount'
  | 'experiment-reassuring-onboarding';

// add additional ab tests here
export const AB_TESTS: ABTestsMap = {
  'demo-ab-test': {
    name: 'demo-ab-test',
    buckets: ['original', 'variant1'],
    startDate: '2000-01-01T00:00:00.000Z',
    useManually: true, // set to true to use the ab test manually
  },
  'experiment-first-time-web-user-discount': {
    name: 'experiment-first-time-web-user-discount',
    buckets: ['original_regular_discount', 'variant1_first_time_discount'],
    startDate: '2024-08-30T00:00:00.000Z',
    useManually: false, // set to true to use the ab test manually
  },
  'experiment-reassuring-onboarding': {
    name: 'experiment-reassuring-onboarding',
    buckets: ['original_no-reassurance', 'variant1_reassurance'],
    startDate: '2024-11-12T00:00:00.000Z',
    useManually: false, // set to true to use the ab test manually
  },
};

// add additional ab test buckets here in their own type
// if you need more than one bucket create a union type using |
// e.g. DemoABTest2Buckets = 'original' | 'variant1'
export type DemoABTest1Bucket = 'original' | 'variant1';
export type ExperimentFirstTimeWebUserDiscount =
  | 'original_regular_discount'
  | 'variant1_first_time_discount';
export type ExperimentReassuringOnboarding =
  | 'original_no-reassurance'
  | 'variant1_reassurance';

// combine all available ab test buckets here as a union type
// e.g. ABTestBuckets = DemoABTest1Buckets
export type ABTestBucket =
  | DemoABTest1Bucket
  | ExperimentFirstTimeWebUserDiscount
  | ExperimentReassuringOnboarding;

/* 
NOTE: How does AB testing work? (also see middleware.tsx)
1. The AB test is defined here, in the ab-tests.ts file
3. Give it a name, define the buckets, and set the start and end dates
5. Add those extra types to the ABTestNames and ABTestBuckets union types

Note: All AB test cookies have an expiry of 1 year set on them. If we remove
an AB test from the AB test list above those cookies will get purged 
automatically by the logic.

Typescript should help you out as much as it can here, e.g. if you create a new AB test and don't use the buckets you have defined for it TS will complain.

*/

/*
NOTE: Example use of the usABTest hook in a component

function DemoComponent() {
  const abTestBucket = useABTest('demo-ab-test');

  useEffect(() => {
    console.log(abTestBucket);
  }, [abTestBucket]);

return (
    <div>
      <h1>AB Test Demo</h1>
      { abTestBucket === 'original' && <p>Original</p>}
    </div>
  );
}
*/

/*
NOTE: Example use of the parseABTestCookiesFromRequestHeader function in getServerSideProps

export const getServerSideProps = withUserTokenSSR({
  whenUnauthed: AuthAction.REDIRECT_TO_LOGIN,
  authPageURL: webAuthPageURL,
})(async (ctx) => {
  const abTestBucketMap = await parseABTestCookiesFromRequestHeader(
    ctx.req.headers.cookie,
  );

  return {
    props: {
      abTestBucketMap,
    },
  };
});

*/
