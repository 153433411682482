import {
  InteractionOption,
  CodingLanguage,
  ExecutableLessonFile,
} from '@customtypes/parsedLesson';
import { isDefined } from './typeGuards';
import { Tutorial, UnlockLevel } from '@customtypes/tutorial';
import { Section } from '@customtypes/section';
import { Track } from '@customtypes/track';
import { Chapter } from '@customtypes/chapter';
import fs from 'fs';

import { LessonContentLanguageType as LessonContentLanguageType } from '@customtypes/lesson';

/**
 * Get the coding language based on the filename
 *
 * @param filename the name of the file including extension
 * @returns the code language of the file
 */
export function getFileCodeLanguage(filename: string): CodingLanguage {
  switch (true) {
    case filename.endsWith('.html'):
      return 'html';
    case filename.endsWith('.js'):
    case filename.endsWith('.jsx'):
      return 'javascript';
    case filename.endsWith('.css'):
      return 'css';
    case filename.endsWith('.sql'):
      return 'sql';
    default:
      return 'html';
  }
}

/**
 * Create interaction option based on the content of the file
 * as well as the start and end index
 *
 * @param content the content of the lesson
 * @param startIndex start index of the interaction
 * @param endIndex end index of the interaction
 * @returns the interaction option
 */
export function calculateInteraction(
  content: string,
  startIndex: number,
  endIndex: number,
): InteractionOption {
  let startLineNumber = 0;
  let startColumn = 0;
  let endLineNumber = 0;
  let endColumn = 0;

  const valueSplit = content.split('\n');
  const indexSplit = [];
  for (let i = 0; i < valueSplit.length; i++) {
    const s: any = i === 0 ? 0 : indexSplit[i - 1][1] + 1;
    indexSplit[i] = [s, s + valueSplit[i].length];
  }

  for (let i = 0; i < indexSplit.length; i++) {
    const line = indexSplit[i];
    if (startIndex >= line[0] && startIndex <= line[1]) {
      startLineNumber = i + 1;
      startColumn = startIndex - line[0] + 1;
    }
    if (endIndex >= line[0] && endIndex <= line[1]) {
      endLineNumber = i + 1;
      endColumn = endIndex - line[0] + 1;
    }
  }
  // startLine: number;
  // startPos: number;
  // length: number;
  // value: string;
  // correct: boolean;
  return {
    startIndex,
    endIndex,
    startLine: startLineNumber - 1,
    startPos: startColumn - 1,
    length: endColumn - startColumn,
    value: valueSplit[startLineNumber - 1].substring(
      startColumn - 1,
      endColumn - 1,
    ),
    correct: true,
  };
}

/**
 * Small helper function which returns the correct code language for
 * the monaco code editor given the language of the lesson file.
 *
 * @param language code language from lesson file
 * @returns code language used in code editor
 */
export function getCodeLanguageForEditor(language: CodingLanguage) {
  switch (language) {
    case 'jsx':
      return 'javascript';

    default:
      return language;
  }
}

/**
 * Escape html string. Replaces `&` with `&amp;` and `<` with `&lt;`
 * for example.
 *
 * @param unsafe the unsafe html string
 * @returns escaped html string
 */
export function escapeHtml(unsafe: string) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

/**
 * Maps the tutorial ids from a section to the actual tutorial objects
 *
 * @param section The section to get the tutorials from
 * @param track  The track to get the sections from
 * @returns The tutorials in the section
 */
export function getTutorialsInSection(section: Section, track: Track) {
  return section.tutorialIds
    .map((tutorialId) =>
      track.tutorials.find((tutorial) => tutorial.id === tutorialId),
    )
    .filter(isDefined);
}

/**
 * Check if the given lesson files contain an `sql` file.
 *
 * @param originalFiles list of executable lesson files
 * @returns true if lesson contains an `sql`file
 */
export function isSqlFileInFiles(originalFiles: ExecutableLessonFile[]) {
  return originalFiles.some((file) => file.codeLanguage === 'sql');
}

/**
 * Get the lesson content language type from a list of lesson files. In essence
 * this returns if the lesson is an HTML, JS only or SQL lesson based on the
 * used file languages.
 *
 * @param originalFiles list of lesson files
 * @returns the lesson content language type
 */
export function getLessonContentLanguageType(
  originalFiles: ExecutableLessonFile[],
): LessonContentLanguageType {
  if (originalFiles.some((file) => file.codeLanguage === 'sql')) {
    return 'SQLLesson';
  }
  if (originalFiles.some((file) => file.codeLanguage === 'html')) {
    return 'HTMLLesson';
  }
  if (originalFiles.every((file) => file.codeLanguage === 'javascript')) {
    return 'JSLesson';
  }
  if (originalFiles.every((file) => file.codeLanguage === 'python')) {
    return 'PythonLesson';
  }

  return 'HTMLLesson';
}

/**
 * Returns a json object list of all supported tracks for Mimo Web from the filesystem.
 */
export function getAllSupportedTracksFromFS(trackIds: number[]) {
  const tracks = trackIds.map((trackId) => {
    return getTrackFromFS(trackId);
  });

  return tracks.map((track) => track.track);
}

/**
 * Returns a track by id from the filesystem. The track object contains
 * all data relevant to the track (tutorials, chapters, lessons).
 *
 * @param trackId the track id
 */
export function getTrackFromFS(trackId: number): {
  track: Track;
  publishSetVersion: number;
} {
  return JSON.parse(
    fs.readFileSync(
      `${process.cwd()}/tracks/tracks/tracks-merged-${trackId}.json`,
      'utf8',
    ),
  );
}

export function getFileExtensionForCodingLanguage(
  codingLanguage: CodingLanguage,
) {
  switch (codingLanguage) {
    case 'html':
      return 'html';
    case 'css':
      return 'css';
    case 'javascript':
      return 'js';
    case 'python':
      return 'py';
    case 'sql':
      return 'sql';
    case 'jsx':
      return 'jsx';

    default:
      throw new Error('Unknown coding language: ' + codingLanguage);
  }
}

export function getCodeLanguageForFileExtension(
  filename: string,
): CodingLanguage {
  if (!filename.includes('.')) {
    return 'none';
  }

  const extension = filename.split('.').pop() || '';

  switch (extension) {
    case 'html':
      return 'html';
    case 'css':
      return 'css';
    case 'js':
      return 'javascript';
    case 'jsx':
      return 'jsx';
    case 'py':
      return 'python';
    case 'sql':
      return 'sql';
    case 'json':
      return 'json';
    case 'ts':
      return 'typescript';
    default:
      return 'none';
  }
}

export function getPrettyNameForCodingLanguage(codingLanguage: CodingLanguage) {
  switch (codingLanguage) {
    case 'html':
      return 'HTML';
    case 'css':
      return 'CSS';
    case 'javascript':
      return 'JavaScript';
    case 'jsx':
      return 'JSX';
    case 'python':
      return 'Python';
    case 'bash':
      return 'Bash';
    case 'cpp':
      return 'C++';
    case 'csharp':
      return 'C#';
    case 'java':
      return 'Java';
    case 'kotlin':
      return 'Kotlin';
    case 'lua':
      return 'LUA';
    case 'php':
      return 'PHP';
    case 'r':
      return 'R';
    case 'ruby':
      return 'Ruby';
    case 'sql':
      return 'SQL';
    case 'swift':
      return 'Swift';
    default:
      return codingLanguage;
  }
}
